
.footer{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.5vw;
    width:100vw;
    height: 20vw;
    
}