.header{
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 9vw;
    font-size: 1.5vw;
    line-height: 1.5;
    
}
.header a{
    display: block;
    text-decoration: none;
    
    color:black;
    
    
}
.header div:nth-child(1) a:nth-child(2){
    margin-top: 2vw;
}
.header div:nth-child(2) a:nth-child(2){
    margin-top: 2vw;
}
.header a:hover {
    color:blue;
}