.todaysTodo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3vw;
  table {
    border-top: 2px solid #369;
    border-collapse: collapse;
    width: 95vw;

    & th:nth-child(1) {
      width: 100px;
      font-size: 1.5vw;
      padding-top: 0.2vw;
      padding-bottom: 0.2vw;
    }

    & th:nth-child(2) {
      padding-top: 0.2vw;
      padding-bottom: 0.2vw;
      font-size: 1.5vw;
    }

    & tr:nth-child(even) {
      background: #effbfb;
    }

    & td:nth-child(1) {
      text-align: center;
      font-size: 1.7vw;
    }

    & td:nth-child(2) {
      font-size: 1.7vw;
      padding-left: 20px;
    }
  }
}
