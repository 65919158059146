.contents {
  display: flex;
  flex-direction: column;
  height: 50vw;
  width: 100vw;
  line-height: 1.5;
  .clockSetWrap {
    display: flex;
    height: 70%;
    position: relative;
    .clock {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../../../img/Clock.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 50%;
      height: 100%;
      .alarm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      .hideAlarm {
        display: none;
      }
    }
    @keyframes birdsAni {
      to {
        background-position: -30vw;
      }
    }

    .singbirdAni {
      position: absolute;
      opacity: 0;
      top: 75%;
      z-index: 9999;
      width: 10vw;
      height: 10vw;
      background: url("../../../img/singbirds.png") no-repeat 0 0 / cover;
      animation: birdsAni 2s 30 steps(3);
      animation-play-state: paused;
    }
    .dsp {
      animation-play-state: running;
      transition: 1s;
      opacity: 1;
    }

    .dsp2 {
      animation-play-state: paused;
      transition: 1s;
      opacity: 0;
    }

    .settings {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50%;
      height: 100%;

      & form div:first-child input {
        text-align: right;
        width: 5vw;
        font-size: 1.5vw;
      }

      & form div:nth-child(2) input {
        text-align: left;
        width: 90%;
        font-size: 1.5vw;
      }

      fieldset {
        border: 1.5px groove skyblue;
        border-radius: 10px;
        padding-top: 1vw;
        padding-bottom: 1vw;
        width: 70%;
        legend {
          background-color: skyblue;
          color: white;
          border-radius: 3px;
          padding-left: 1vw;
          padding-right: 1vw;
          font-size: 1.5vw;
        }

        input:focus {
          outline: 1px solid skyblue;
        }
        div label {
          padding-right: 0.5vw;
          padding-left: 0.5vw;
        }
        .submitBtn {
          background-color: skyblue;
          cursor: pointer;
          border-radius: 3px;
          border: 1px solid skyblue;
          color: white;
          width: 100%;
          font-size: 1.5vw;
          padding: 1vw;
        }

        .submitBtn:hover {
          border: 1px solid green;
          background-color: green;
        }
      }

      label {
        font-size: 1.5vw;
      }

      .soundName {
        margin-left: 0.5vw;
        text-decoration: underline;

        & + img {
          width: 1.3vw;
          height: 1.3vw;
        }
      }
      .ADstyle {
        width: 73%;
        text-align: left;
        margin-top: 0.7vw;
        margin-left: 1vw;
        /* 토글(어두운 모드) 를 제외한 checkbox */
        input[type="checkbox"]:not(.toggle) {
          width: 1.3vw;
          height: 1.3vw;
          margin-left: 0.7vw;
        }

        & > button {
          background-color: #ccc;
          cursor: pointer;
          border-radius: 3px;
          border: 1px solid #ccc;
          color: white;
          width: 40%;
          font-size: 1.5vw;
          border-radius: 0.5vw;
          padding: 0.3vw;
        }

        & > button:hover {
          border: 1px solid skyblue;
          background-color: skyblue;
        }
      }
    }
  }

  .timeLine {
    font-size: 2.2vw;
    text-align: center;
    width: 50%;
  }
}

/* 
@keyframes handAni{
0%{
    
}
100%{
transform: rotate(360deg);

}
}

.clock .sec{
    position: absolute;
    animation: handAni 60s forwards linear infinite;
    width: 70%;
    height: 70%;
}

.clock .min{
    position: absolute;
    animation: handAni 7200s forwards linear;
    width: 90%;
    height: 80%;
    
}
.clock .hour{
    position: absolute;
    animation: handAni 30s forwards linear;
    width: 90%;
    height: 60%;
} */

/* 
  
  .switch > input {
    display:none;
  }
  
  
  .slider {
    display:inline-block;
    position: relative;
    cursor: pointer;
    background-color: #ccc;
    width:2vw;
    height: 1vw;
    padding: .2vw;
    transition: .3s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 1vw;
    width: 1vw;
    
    
    background-color: white;
    
    transition: .3s;
  }
  
  input:checked + .slider {
    background-color: skyblue;
  }

  
  input:checked + .slider:before {
    
    transform: translateX(1vw);
  }
  
  
  .slider.round {
    border-radius: 50px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } */
